@keyframes twinkle {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
body,
html {
  background-color: white !important;
}

.hidden {
  display: none !important;
}
.block {
  display: block !important;
}
.flex {
  display: flex !important;
}

.MuiPopover-root {
  z-index: 9999 !important;
}
.animate__duration-infinite {
  animation-duration: 300s !important;
  animation-iteration-count: infinite !important;
}

.sendbird-theme--light .sendbird-conversation {
  border: 0px;
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
.sendbird-channel-list__header {
  min-height: 73px;
  height: 73px;
}
.sendbird-channel-header {
  min-height: 73px;
  height: 73px;
  width: 318px;
}
.sendbird-theme--light .sendbird-channel-header {
  background: transparent;
  border-bottom: 2px solid #f3f3f3;
}
.sendbird-theme--light .sendbird-channel-preview--active {
  background: rgba(226, 226, 241, 0.2);
  border-left: 0px;
  border-bottom: 0px;
}
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper
  .sendbird-channel-preview__content__upper__last-message-at {
  color: rgba(143, 160, 175, 1);
}
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper
  .sendbird-channel-preview__content__upper__header
  .sendbird-channel-preview__content__upper__header__channel-name {
  color: #0e1114;
  font-size: 14px;
  font-weight: 600;
}
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__lower
  span {
  font-weight: 500;
  font-size: 14px;
  color: #616c76;
}

.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__lower {
  height: 36px;
}

a span.gradient {
  background: linear-gradient(104.51deg, #c72a8e 3.97%, #7815da 97.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sendbird-message-content.incoming
  .sendbird-message-content__middle
  .sendbird-message-content__middle__sender-name {
  background: linear-gradient(104.51deg, #c72a8e 3.97%, #7815da 97.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 14px;
  margin-left: 0;
  overflow: hidden;
}

.sendbird-conversation {
  width: 100%;
  height: 100%;
}

.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  overflow: hidden;
  overflow-y: scroll;
}

.sendbird-conversation__footer {
  padding: 0px;
}
.sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  padding-left: 16px;
  padding-right: 16px;
}
.sendbird-theme--light
  .sendbird-message-input
  .sendbird-message-input--textarea {
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  overflow: hidden;
}
.sendbird-conversation__footer {
  margin: 0;
}
.sendbird-text-message-item-body.outgoing {
  background-color: #a321b0 !important;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 0;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.sendbird-text-message-item-body.incoming {
  background: rgba(226, 226, 241, 0.4);
  color: #0e1114;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 12px;
}
.sendbird-message-status .sendbird-message-status__text {
  font-size: 10px;
}
.sendbird-separator__left,
.sendbird-separator__right {
  visibility: hidden !important;
}
.sendbird-separator .sendbird-separator__text {
  background: #bdbdbd;
  padding: 5px;
  background: #ebebeb;
  padding: 5px 10px;
  border-radius: 12px;
}
.sendbird-separator .sendbird-separator__text span {
  font-size: 11px;
}

a {
  text-decoration: none;
}

.MuiFormControl-root {
  z-index: 1;
}
.MuiPopover-paper {
  margin-top: 10px;
}
.MuiPopover-paper .MuiCheckbox-root {
  color: black !important;
}
.MuiPopover-paper .MuiTypography-root {
  font-size: 14px;
  font-weight: 500;
}

.fixed-header {
  position: inherit;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 950px) {
  .fixed-header {
    position: fixed;
    left: 320px;
    width: calc(100% - 320px);
    background: white;
    padding: 15px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 19%);
  }
}
